import React from 'react'
import './style.scss'
//import NaviContext from '../../context/NaviContext'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTwitter, faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons'

function SocialIcons(props) {
	//const naviContext = useContext(NaviContext)
	
  return (
		<>
		<div className="social-container">
			<a className="underline" href="#">FACEBOOK</a><br/>
			<a className="underline" href="#">TWITTER</a><br/>
			<a className="underline" href="#">INSTAGRAM</a><br/>
			<a className="underline" href="#">LINKEDIN</a><br/>
		</div>
		</>
  )
}

export default SocialIcons