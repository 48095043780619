import React, {useState, createContext, useEffect} from 'react'
import { useQuery, gql } from '@apollo/client'

const ApolloContext = createContext(false)

function ApolloContextProvider({children, location}) {
  // State for telling if the Apollo is active or not
	const [isLoading, setLoading] = useState(false)
	const [data, setData] = useState()
  const [nextQuery, setNextQuery] = useState(false)
  const [currentOffset, setCurrentOffset] = useState(0)

  // Apollo navi query
  const { loading, error, data: apollo, fetchMore } = useQuery(APOLLO_QUERY, {
    variables: {size : 4, offset : null},
    notifyOnNetworkStatusChange: true,
  } )

  useEffect(() => {
    nextQuery ? setNextQuery(false) : setNextQuery(true)

    fetchMore({
      variables: {offset: currentOffset},
      updateQuery: (prevResult, { fetchMoreResult }) => {
        return fetchMoreResult;
      }
    })

  }, [currentOffset])

  useEffect(() => {
		setLoading(loading)
		// If loaded rehydrate
		if(!loading && !error) {
			setData(apollo)
		}
  },[loading, error, nextQuery])

	return (
		<ApolloContext.Provider value={{
      isLoading,
			data,
      currentOffset,
      setCurrentOffset,
    }}>
      {children}
    </ApolloContext.Provider>
	)
}

export default ApolloContext
export { ApolloContextProvider }

// WPGRAPHQL Query for rehydration, posts
const APOLLO_QUERY = gql`
query apolloQuery($size: Int, $offset: Int)
{
  posts: posts(where: { offsetPagination: { size: $size, offset: $offset } } ) {
    nodes {
      id
      slug
      title(format: RENDERED)
      uri
      status
      postId
      content(format: RENDERED)
      excerpt(format: RENDERED)
      date
      featuredImage {
        node {
          id
          srcSet
        }
      }
      author {
        node {
          name
          slug
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }
    } 
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
      offsetPagination {
        hasMore
        hasPrevious
        total
      }
    }
  }
  compactPosts: posts(first: 4) {
    nodes {
      id
      slug
      title(format: RENDERED)
      uri
      status
      postId
      content(format: RENDERED)
      excerpt(format: RENDERED)
      date
      featuredImage {
        node {
          id
          srcSet
        }
      }
      author {
        node {
          name
          slug
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }
    } 
  }
}
`  