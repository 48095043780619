import React from 'react'
import './style.scss'


function Contact(props) {

    return (
		<div className='contact'>
            <h4>Contact Us</h4>
			<p>
				<span>P</span> <a href="tel:3054405401">305 440 5401</a> <br/>
				<span>E</span> <a className="underline" href="mailto:gosolar@solarmy.com">gosolar@solarmy.com</a>
			</p>
			<p>
				4224 NW 72nd ave <br/>
				Miami FL 33166
			</p>
        </div>
    )
}

export default Contact