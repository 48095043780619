import React, {useContext} from 'react'
import './style.scss'
import NaviContext from '../../context/NaviContext'
import { Transition, SwitchTransition } from 'react-transition-group'
import anime from 'animejs'

import Hamburger from './Hamburger'
import NaviPrimary from './Navi/Primary'
import Logo from './Logo'
import Contact from './Contact'

import SocialIcons from '../SocialIcons'

function Header(props) {
	const naviContext = useContext(NaviContext)

	let routes = ["discover", "our-work", "contact", "consultation"];
	let location = naviContext.locationPathname
	let darkLayout = false
	if(typeof location === 'string')
		darkLayout = routes.some(route => location.includes(route));
	
	// Animating fade in/out
	const baseDuration = 500
	const fadeInLogo = element => {
		const links = element.querySelectorAll('.nav-item, .social-icons a')
		anime
			.timeline()
			.add({
				targets: element,
				opacity: [0, 1],
				//translateX: [-100, 0],
				duration: baseDuration,
				easing: 'easeInOutSine',
			})
			.add({
				targets: links,
				opacity: [0, 1],
				translateX: [-32, 0],
				duration: baseDuration / 2,
				easing: 'easeInOutSine',
				delay: anime.stagger(75)
			}, `-=${baseDuration / 3}`)
	}
	const fadeOutLogo = element => {
		anime
			.timeline()
			.add({
				targets: element,
				opacity: [1, 0],
				duration: baseDuration / 2,
				easing: 'linear'
			})
	}

	const fadeIn = element => {
		anime
			.timeline()
			.add({
				targets: element,
				opacity: [0, 1],
				duration: baseDuration,
				easing: 'easeInOutSine',
			})
	}
	const fadeOut = element => {
		anime
			.timeline()
			.add({
				targets: element,
				opacity: [1, 0],
				duration: baseDuration / 2,
				easing: 'linear'
			})
	}

  return (
		<>
			<Transition
				in={naviContext.isActive && !naviContext.windowSize?.mobile ? true :	false}
				timeout={baseDuration}
				appear={true}
				onEntering={fadeInLogo}
				onExiting={fadeOutLogo}
				mountOnEnter
				unmountOnExit
			>
				<header className={'master-header skip-animation '}>
					
					<div className={`navi-animator  flex-12`}>
						<div className='span-6 c3'>
						<div className="navi-wrap">
				
							<NaviPrimary {...props} />
						</div>
						</div>
					
					<div className="span-6 c4 right">
						<Contact />
						<SocialIcons />
						<Hamburger />
					</div>

					</div>
				
				</header>
			</Transition>
		
			
			<div className={`header-bar-new ${darkLayout && !naviContext.isHamburgerActive ? 'dark-layout' : ''} ${naviContext.windowSize?.mobile ? 'onmobile' : ''} ${naviContext.isHamburgerActive ? 'open inview ' : ''} ${naviContext.isHamburgerActive && naviContext.windowSize?.mobile ? 'c3' : ''}`}>

				<div className={`regular ${naviContext.windowSize?.mobile ? 'onmobile' : ''} ${darkLayout && !naviContext.isHamburgerActive ? 'dark-layout' : ''}`}>

					<Hamburger type={naviContext.beforeHeaderBreakpoint} />
					<Logo stickyNavbar={true} />

				</div>

				<Transition
					in={naviContext.windowSize?.mobile && naviContext.isHamburgerActive ? true : false}
					timeout={baseDuration}
					appear={true}
					onEntering={fadeIn}
					onExiting={fadeOut}
					mountOnEnter
					unmountOnExit
				>
					<div className="mobile flex-12 ">
						<div className="span-12 top c3">
							<div className="navi-wrap">
								<NaviPrimary {...props} />
							</div>
						</div>
						<div className='span-12 bottom c4'>
							<Contact />
							<SocialIcons />	
						</div>
					</div>
				</Transition>
				
				{/* {naviContext.locationPathname !== '/'  ?
					<div className="bg-overlay-fix"></div>
					:
					null
				} */}
			
				<Transition
					in={!naviContext.beforeHeaderBreakpoint && naviContext.locationPathname !== '/about/'}
					timeout={200}
					appear={true}
					onEntering={fadeIn}
					onExiting={fadeOut}
					mountOnEnter
					unmountOnExit
				>
					<div className={`bg-overlay-fix`}></div>
				</Transition>
				
			</div>

		</>
  )
}

export default Header